// import { ArrowLeft } from '@element-plus/icons';
// import moment from 'moment';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
// import GlobalDynamicHeader from '@/core/components/common/header/GlobalDynamicHeader.vue';
import AddReviewModal from '@/core/components/common/modals/AddReviewModal.vue';
import EstimatePhaseAttachmentUploadModal from '@/core/components/common/modals/EstimatePhaseAttachmentUploadModal.vue';
// import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';
import BalanceView from '@/core/components/ui/BalanceView.vue';
import Loader from '@/core/components/ui/Loader.vue';
import { ProjectStageTypeEnum } from '@/core/packages/shared-library';
import EstimateSummaryBoxes from '@/modules/project-schedule/components/EstimateSummaryBoxes.vue';
import PhaseItemComponent from '@/modules/project-schedule/components/PhaseItemComponent.vue';
import { PROJECTS_ROUTE } from '@/modules/projects/routes';
import { JOB_TEMPLATE_STORE } from '@/store/modules/job-template';
import { PROJECT_QUOTES_STORE } from '@/store/modules/project-quotes';
import { PROJECT_STAGES_TASKS_STORE } from '@/store/modules/project-quotes/submodules/project-stages-tasks';
import { PROJECTS_STORE } from '@/store/modules/projects';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    name: 'estimate-schedule',
    components: {
        EstimateSummaryBoxes,
        PhaseItemComponent,
        AddReviewModal,
        BalanceView,
        Loader,
        // GlobalDynamicHeader,
        // ActionItemComponent,
        EstimatePhaseAttachmentUploadModal
    },
    created() {
        this.initialize();
    },
    data() {
        return {
            colors: ['#4A92E5', '#D84F50', '#EB8C49', '#5DBA59', '#8454DD', '#3E72E1'],
            isOwner: true,
            isScroll: false,
            selectedProject: null,
            selectedEstimate: null,
            originalEstimate: null,
            ProjectStageTypeEnum,
            openUserRatingModal: false,
            hasUpdatedEstimate: false,
            hasUpdatedEstimateTasks: false,
            clickedPhaseIndex: null,
        };
    },
    computed: {
        ...mapGetters(PROJECTS_STORE, ['projectAccountBalance']),
        ...mapGetters(['isTradesperson', 'isProjectOwner', 'isLoading']),
        ...mapGetters(USERS_STORE, ['user']),
        ...mapGetters(JOB_TEMPLATE_STORE, ['showPhaseUploadAttachmentModal']),
        totalCost() {
            if (this.selectedEstimate?.projectStages) {
                return this.selectedEstimate?.projectStages.reduce((sum, projectStage) => sum + projectStage?.stageCost, 0);
            }
            return 0;
        },
        isAccepted() {
            return parseInt(this.selectedEstimate?.projectQuoteStatus.id, 10) >= 2;
        },
    },
    methods: {
        ...mapActions(['setIsLoading']),
        ...mapActions(USERS_STORE, ['getUserRatingsPageList', 'createUserRating', 'getUserRatingSummary']),
        ...mapActions(PROJECTS_STORE, ['getProjectByRefId']),
        ...mapActions(PROJECT_QUOTES_STORE, ['getProjectQuoteDetails', 'updateProjectQuote', 'getCustomerPay']),
        ...mapActions(PROJECT_STAGES_TASKS_STORE, [
            'checkAllProjectStagesIfCompleted'
        ]),
        ...mapActions(JOB_TEMPLATE_STORE, ['setShowPhaseUploadAttachmentModal']),
        getMappedPhases(x, i) {
            // const diff = moment.duration(moment(x.endDate).startOf('day').diff(moment(x.startDate).startOf('day')));
            const modifiedPhase = {
                expand: true,
                color: x.color || this.colors[i % 6],
            };
            return Object.assign(x, modifiedPhase);
        },
        routeToProjectOverview() {
            this.$router.push({ name: 'overview' }).then(() => { this.$router.go(); });
        },
        onChangeRequest() {
            this.$router.push({ name: 'estimate-form' });
        },
        async initialize() {
            const { projectRefId, quoteId } = this.$route.params;
            if (!quoteId) {
                this.routeToProjectOverview();
            }
            await this.getProjectByRefId(projectRefId)
                .then(async (response) => {
                if (response) {
                    this.selectedProject = response;
                    if (response.projectQuotes) {
                        const estimate = response.projectQuotes.find((x) => x.id === Number(quoteId));
                        if (estimate) {
                            const quotePrice = await this.getProjectQuoteDetailsWithTotalQuotePrice(estimate);
                            estimate.quotePrice = quotePrice;
                            estimate.quoteTotalDuration = estimate.projectStages.filter((item) => item.type === ProjectStageTypeEnum.LABOUR)
                                .map((stage) => {
                                return stage.totalDuration;
                            }).reduce((a, b) => a + b, 0);
                            const mappedEstimate = {
                                ...estimate,
                                projectStages: estimate.projectStages.map(this.getMappedPhases),
                            };
                            this.selectedEstimate = mappedEstimate;
                            this.originalEstimate = mappedEstimate;
                            this.isAllProjectStagesCompleted();
                        }
                    }
                }
            })
                .catch((error) => {
                this.$notify.error({
                    message: error && error?.message ? error?.message : 'Project not found.'
                });
                this.$router.push({ name: PROJECTS_ROUTE });
            })
                .finally(() => {
                this.setIsLoading(false);
            });
        },
        async isAllProjectStagesCompleted() {
            const { selectedEstimate } = this;
            const hasInProgress = await this.checkAllProjectStagesIfCompleted(selectedEstimate?.projectStages);
            if (!hasInProgress && this.isProjectOwner) {
                const checkPOHasRatedResponse = await this.getUserRatingsPageList({
                    userId: selectedEstimate?.projectQuoteCreator?.id,
                    filter: {
                        refId: this.selectedProject?.id,
                    }
                });
                if (!checkPOHasRatedResponse?.data?.length) {
                    this.openUserRatingModal = true;
                }
            }
        },
        onCloseReviewModal() {
            this.openUserRatingModal = false;
        },
        async onCreateUserRating(payload) {
            await this.createUserRating(payload)
                .then(() => {
                this.$notify.success({
                    message: 'Rating successfully submitted.'
                });
            }).catch(() => {
                this.$notify.error({
                    message: 'Rating submission failed.'
                });
            })
                .finally(() => {
                this.openUserRatingModal = false;
            });
        },
        async getProjectQuoteDetailsWithTotalQuotePrice(projectQuote) {
            let result = {
                total: 0,
                serviceFee: 0,
            };
            const projectId = this.selectedProject?.id;
            const projectQuoteDetails = await this.getProjectQuoteDetails({ projectQuoteId: projectQuote?.id, projectId });
            result = await this.getCustomerPay({ projectId: this.selectedProject?.id, quote: projectQuoteDetails });
            return result;
        },
        backToEstimates() {
            const { projectRefId } = this.$route.params;
            this.$router.push({ name: 'project-estimate', params: { projectRefId } });
        },
        updateEstimate(updatedEstimate) {
            const estimate = JSON.parse(updatedEstimate);
            this.hasUpdatedEstimate = true;
            this.selectedEstimate = estimate;
        },
        updateEstimateTasks(projectStages) {
            this.selectedEstimate.projectStages = projectStages;
            this.hasUpdatedEstimateTasks = true;
        },
        downLoadPdf() {
        },
        onClosePhaseAttachmentModal() {
            this.setShowPhaseUploadAttachmentModal(false);
            this.clickedPhaseIndex = null;
        },
        setClickedPhaseIndex(index) {
            this.clickedPhaseIndex = index;
            if (this.clickedPhaseIndex !== null && this.clickedPhaseIndex !== 'undefined') {
                this.setShowPhaseUploadAttachmentModal(true);
            }
        }
    }
});
